.filters {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  max-width: 400px;
  margin: 20px auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.filters label {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
}

.filters input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.filters input:focus {
  border-color: #007bff;
  outline: none;
}

.filters button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filters button:hover {
  background-color: #0056b3;
}

.header .rowDiv {
  flex-wrap: wrap;
  /* flex-direction: column; */
  display: flex;
}
