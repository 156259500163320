.OrderModel .items h3 {
  text-align: center;
  width: fit-content;
  padding: 10px 15px;
  border-bottom: 0.1px solid rgb(217, 217, 217);
  margin: 10px auto;
}
.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
/* .model {
  user-select: all;
} */
.btnsContainer {
  width: 80%;
  margin: 0 auto;
}
.btnsContainer .btns {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.btnn {
  margin: 15px;
}
.btnn label {
  width: 120px;
}
.codeContainer {
  width: 90%;
  /* margin: auto; */
  /* padding: 10px; */
  justify-content: flex-start;
}
.codeContainer textArea {
  width: -webkit-fill-available;
  border: none;
  text-align: left;
  user-select: all;
  height: 70px;
}

.copyBtn {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid gray;
}
.copyBtn span {
  background-color: transparent;
  border: solid 1px var(--main-color-1);
  border-radius: 5px;
  color: var(--main-color-1);
  font-weight: 600;
  font-size: 17px;
  transition: 0.5s ease-in-out;
  margin: 5px;
  display: flex;
  justify-content: center;
  width: 50px;
  cursor: pointer;
}
.Contan {
  margin: 30px;
}
.main {
  width: 80%;
  background-color: late;
  margin: 0 auto;
  /* justify-content: center; */
  padding: 40px 20px;
  border-radius: 5px;
  display: block;
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.03),
    0 3px 1px -2px rgba(0, 0, 0, 0.03),
    0 1px 5px 0 rgba(0, 0, 0, 0.03);
  color: red;
}
.main .image {
  padding: 20px;
}
.main .image img {
  height: 230px;
  width: 230px;
  border-radius: 50%;
  margin: 0 auto;
}
.main .content {
  padding: 5px;
}
.main .content .C_title {
  margin: 2px;
}
.main .content h1 {
  color: black;
  font-size: 42px;
  line-height: 42px;
  margin-bottom: 5px;
  /* justify-content: center;
  display: flex; */
}
.main .content h5 {
  color: #848484 !important;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
}
.main .content .qrContainer {
  display: flex;
  justify-content: center;
  width: 150px;
  /* height: 100px; */
}
.linkk {
  background-color: transparent;
  border: solid 1px var(--main-color-1);
  border-radius: 5px;
  color: var(--main-color-1);
  font-weight: 600;
  font-size: 17px;
  transition: 0.5s ease-in-out;
  margin: 5px;
  display: flex;
  justify-content: center;
  width: 150px;
  cursor: pointer;
  text-direction: none;
}
.main .content .info {
  /* margin: 10px 0; */
  /* padding: 10px; */
}

.main .content .info .item {
  justify-content: flex-start;
  display: flex;
  margin: 0 0 0 0px;
}

/* .OrderModel .items h2 {
  text-align: center;
  width: fit-content;
  margin: auto;
  padding: 10px 15px;
}

.OrderModel {
  padding: 12px;
  width: min(400px, 100%);
  width: 80%;
  position: fixed;
  overflow-y: auto;
  height: 100%;
  z-index: 9999999999999999999 !important;
  top: 0;
  left: 43%;
  translate: -50%;
  background: white;
  box-shadow: 1px 0 24px -2px rgb(217, 217, 217);
  border-radius: 4px;
}

.table_btns button {
  font-size: 14px;
  white-space: nowrap;
}

.table_btns {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  font-size: 14px;
}

#order-status-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  justify-content: center;
  width: 264px;
  margin: 7px 0;
}

#order-status-form label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

#order-status-form input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

#order-status-form button[type="submit"] {
  padding: 10px 20px;
  background-color: #1e90ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

#order-status-form input {
  display: none !important;
}

#order-status-form input:checked + span {
  background: rgb(85, 16, 16);
  color: white;
}

.edit_model {
  padding: 10px;
  position: fixed;
  left: 5%;
  top: 30%;
  background: white;
  box-shadow: 1px 0px 24px -3px rgb(204, 204, 204);
  border-radius: 5px;
}
@media print {
  body {
    margin: 0;
    overflow: hidden;
  }

  .printable {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .OrderModel .items h3 {
    text-align: center;
    width: fit-content;
    padding: 10px 15px;
    border-bottom: 0.1px solid rgb(217, 217, 217);
    margin: 10px auto;
  }

  .OrderModel .items h2 {
    text-align: center;
    width: fit-content;
    margin: auto;
    padding: 10px 15px;
  }

  .OrderModel {
    padding: 12px;
    width: min(400px, 100%);
    width: 80%;
    position: fixed;
    overflow-y: auto;
    height: 100%;
    z-index: 999999999999999999999999999999999999999999999999999999999999999999 !important;
    top: 0;
    left: 43%;
    translate: -50%;
    background: white;
    box-shadow: 1px 0 24px -2px rgb(217, 217, 217);
    border-radius: 4px;
  }

  .table_btns button {
    font-size: 14px;
    white-space: nowrap;
  }

  .table_btns {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    font-size: 14px;
  }

  #order-status-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    justify-content: center;
    width: 264px;
    margin: 7px 0;
  }

  #order-status-form label {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }

  #order-status-form input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }

  #order-status-form button[type="submit"] {
    padding: 10px 20px;
    background-color: #1e90ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }

  #order-status-form input {
    display: none !important;
  }

  #order-status-form input:checked + span {
    background: rgb(85, 16, 16);
    color: white;
  }

  .edit_model {
    padding: 10px;
    position: fixed;
    left: 5%;
    top: 30%;
    background: white;
    box-shadow: 1px 0px 24px -3px rgb(204, 204, 204);
    border-radius: 5px;
  }
}

.filteration {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  width: 100%;
}

.message_details p {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
}

.message_details p pre {
  margin: 0;
  font-weight: 700;
  white-space: pre-wrap;
}

*/

@media (max-width: 792px) {
  .MuiGrid-root.MuiGrid-container {
    flex-direction: column !important;
    justify-content: center !important;
    /* align-items: center !important; */
  }

  /* .main .image img {
    width: 100%;
  } */

  .main {
    width: 100%;
  }

  .MuiGrid-root.MuiGrid-item,
  .main .image,
  .MuiGrid-root.MuiGrid-container > div {
    max-width: 100% !important;
  }
  .main .content h1 {
    font-size: 22px;
  }

  .main .image img {
    height: 170px;
    width: 170px;
  }
}
