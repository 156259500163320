* {
  font-family: "Tajawal", sans-serif;
  text-align: right;
  box-sizing: border-box;
  position: relative;
  direction: rtl;
}

div#root,
.App {
  width: 100%;
  min-height: 100vh;
}

/* Media */
.invoice_actions > * {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-toggle,
.list-close {
  cursor: pointer;
  /* display: none; */
  font-size: 23px;
}
aside {
  width: 290px !important;
  /* z-index: 9999999999999999999999999999999 !important; */
  padding: 30px 21px 19px 0 !important;
  position: fixed !important;
  right: -150% !important;
  transition: 0.5s ease-in-out;
}
aside.active {
  right: 0 !important;
  z-index: 99999999999999999999999999999999999999999 !important;
}
.filter_page,
.filter_page > * {
  flex-wrap: wrap;
}

.ratios_page .ratio_cat {
  width: 199px !important;
}

.date-d {
  flex-wrap: wrap;
}

.side {
  z-index: 1 !important;
}

td img {
  width: 50px;
}

.OrderMode {
  z-index: 999999999999999999999999999999999999999999999999999999999999999999 !important;
}

@media (max-width: 1110px) {
  .list-toggle,
  .list-close {
    display: flex;
  }

  .OrderModel {
    width: 100%;
    height: 100%;
    white-space: break-spaces !important;
    top: 0;
    left: auto !important;
    right: 0 !important;
    translate: 0 !important;
    background: white;
    box-shadow: 1px 0 24px -2px rgb(217, 217, 217);
    border-radius: 4px;
  }

  .profileMenu .top_info,
  .profileMenu .top_info svg {
    font-size: 14px !important;
  }

  .site-content > div {
    padding: 30px 0 !important;
  }

  span.imgSpan {
    width: 40px !important;
    height: 40px !important;
    font-size: 25px !important;
  }

  header {
    padding-left: 12px !important;
  }

  .site-content {
    /* width: calc(100% - 370px) !important; */
    height: -moz-fit-content !important;
    height: fit-content !important;
    width: 100% !important;
    /* overflow: visible !important; */
  }

  .site-content > div {
    padding: 46px 0px 4px 4px;
    max-width: 100%;
    /* overflow-x: auto; */
    white-space: nowrap;
  }

  .table-t {
    overflow-x: auto;
  }

  .childs {
    padding: 11px !important;
  }

  .common-cl {
    padding: 17px;
    overflow: visible;
  }

  form.formTypeTwo textarea,
  form.formTypeTwo input {
    width: 100% !important;
  }

  form.formTypeTwo > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    white-space: break-spaces;
  }

  /* .table-t {
    width: fit-content !important;
  } */
  .table-tc.table-t {
    width: 100% !important;
  }

  .common-cl {
    width: 100% !important;
  }

  form.formTypeTwo .filefield,
  form.formTypeTwo .inputField label {
    width: 100%;
  }

  .input_file_field_logo.edit_logo_form button {
    width: 100%;
  }

  .input_file_field_logo.edit_logo_form {
    width: 90%;
    padding: 10px;
    box-shadow: 1px 0px 24px -2px rgb(230, 230, 230);
    border-radius: 4px;
    padding: 15px;
    position: fixed;
    background: white;
    z-index: 9999;
    top: 50%;
  }

  .w_btns {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }

  form button {
    width: 90% !important;
  }

  .ratios_page {
    flex-wrap: wrap;
  }

  .filefield,
  .filefield label {
    white-space: wrap;
  }
}

.rgt-columns-manager-popover {
  right: 0 !important;
  left: 50% !important;
}

form#type_1 button {
  width: 100%;
}

.childs {
  padding: 30px;
  width: 100%;
}

table thead th {
  white-space: nowrap;
}

.person img {
  width: 60px;
}

.copyBtn {
  justify-content: flex-start !important;
}

.copyBtn input,
.copyBtn .C_title {
  width: 100% !important;
}

.Toastify {
  z-index: 1222312312312312312132 !important;
}
