body.en_version * {
  direction: ltr !important;
  text-align: left !important;
}

body.en_version .C_modal {
  right: auto !important;
  left: 50% !important;
  translate: -50% !important;
}

body.en_version .profileMenu {
  margin-right: 0 !important;
  margin-left: auto !important;
}

@media (max-width: 1110px) {
  body.en_version aside.active {
    right: auto !important;
    left: 0 !important;
  }
  body.en_version aside {
    right: auto !important;
    left: -150% !important;
  }
}

body.en_version aside .logo-ic img {
  margin-left: auto !important;
}

body.en_version .logo-ic {
  display: flex;
}

body.en_version input[type="checkbox"] {
  width: 100%;
  height: 100%;
}

body.en_version .btn {
  text-align: center !important;
}

body.en_version .languageConverter {
  flex-direction: row !important;
}

body.en_version .languageConverter {
  flex-direction: row !important;
}
