.section {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.section .id {
  width: fit-content;
}

.inputField {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 12px 0;
}

.inputField input,
.inputField textarea,
.filefield,
form textarea,
select,
.type_image label {
  width: 100%;
  padding: 16px 12px 17px 6px;
  border-radius: 10px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  margin-top: 5px;
}

.type_image label {
  cursor: pointer;
}

input[type="file"] {
  display: none;
}

.big_text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 126px;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data__about {
  margin: 15px auto;
  width: fit-content;
}

.tab__btn {
  margin: 0 5px;
}

.data__about.exp{
  margin: 5px 0;
}