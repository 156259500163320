.service form {
  flex-direction: column;
  display: flex;
  gap: 10px;
  /* align-items: center; */
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
}

.service form h1 {
  font-size: 20px;
  font-weight: 600;
  margin: auto;
}
